import React from 'react'
import { Menu } from '@reach/menu-button'
import { useStaticQuery, graphql } from 'gatsby'
import { NavigationMenuQuery } from 'typings/contentful-queries'
import HamburgerButton from '@components/HamburgerButton'
import { NavList, NavListLink } from './Styles'

const Navigation = () => {
  const onLinkClick = () => {
    document.getElementById('page-header')?.focus()
  }
  const data: NavigationMenuQuery = useStaticQuery(graphql`
    query NavigationMenu {
      contentfulMenu(contentful_id: { eq: "5WrxLzm6DOvuomtqfaWMN1" }) {
        menuItems {
          id
          linkPath
          title
        }
      }
    }
  `)

  const navigationMenu = data.contentfulMenu?.menuItems

  return (
    <>
      <Menu>
        <HamburgerButton />
        <NavList portal={false} id="menu--1">
          {navigationMenu &&
            navigationMenu.map(menuItem => (
              <NavListLink
                to={`/${menuItem?.linkPath}`}
                role="menuitem"
                gatsbyLinkProps={{ role: 'menuitem' }}
                onAnchorLinkClick={onLinkClick}
                key={menuItem?.id}
              >
                <span>{menuItem?.title}</span>
              </NavListLink>
            ))}
        </NavList>
      </Menu>
    </>
  )
}

export default Navigation
