/* eslint-disable @typescript-eslint/no-explicit-any */
import { css } from '@emotion/react'

export const sizes = {
  large: 1440,
  desktop: 1200,
  laptop: 1100,
  tabletLarge: 1024,
  tablet: 768,
  thone: 600,
  phablet: 480,
  phone: 376,
  tiny: 330,
}

// iterate through the sizes and create a media template
const under = (Object.keys(sizes) as Array<keyof typeof sizes>).reduce((acc, label) => {
  acc[label] = (first: any, ...interpolations) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(first, ...interpolations)}
    }
  `
  return acc
}, {} as { [key in keyof typeof sizes]: typeof css })

const over = (Object.keys(sizes) as Array<keyof typeof sizes>).reduce((acc, label) => {
  acc[label] = (first: any, ...interpolations) => css`
    @media (min-width: ${(sizes[label] + 1) / 16}em) {
      ${css(first, ...interpolations)}
    }
  `
  return acc
}, {} as { [key in keyof typeof sizes]: typeof css })

export { under, over }
