import styled from '@emotion/styled'

const Svg = styled.svg<{ size: number }>`
  fill: ${({ theme }) => theme.colors.white};
  width: ${props => props.size}px;
`

const ChevronContainer = styled.div`
  display: flex;
  align-items: center;
`

export { Svg, ChevronContainer }
