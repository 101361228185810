import r from '@utils/pxToRem'

export default {
  breakpoints: {
    xs: '36em',
    sm: '48em',
    md: '61.250em',
    lg: '75em',
  },
  colors: {
    darkBlue: '#002455',
    lightBlue: '#2997FF',
    skyBlue: '#47C8FF',
    lightGrey: '#E6E6E6',
    white: '#FFFFFF',
    textBlack: '#1E1E1E',
    textGrey: '#606060',
  },
  fonts: {
    verdana: '"Verdana", "Geneva", sans-serif;',
    montserrat: '"Montserrat", sans-serif',
    lato: '"Lato", sans-serif',
  },
  fontWeights: {
    navigation: 500,
    heading: 600,
    body: 400,
    button: 600,
    accordionHeading: 700,
    footerLink: 700,
  },
  fontSizes: {
    navigation: [r`16px`, r`16px`, r`20px`],
    heading: [r`28px`, r`22px`, r`16px`],
    body: [r`16px`, r`12px`],
    button: [r`18px`, r`16px`],
    accordionHeading: [r`22px`, r`16px`],
    footerText: [r`13px`, r`11px`],
    footerLink: [r`13px`, r`11px`],
    backToTop: [r`14px`, r`12px`],
  },
  lineHeights: {
    navigation: r`24px`,
    heading: [r`42px`, r`33px`, r`24px`],
    body: [r`24px`, r`18px`],
    button: [r`26px`, r`21px`],
    accordionHeading: 1.8,
    footerText: [r`18px`, r`16px`],
    footerLink: [r`18px`, r`16px`],
  },
  elementSizes: {
    header: [92, 54],
    maxPageWidth: 1200,
  },
  boxShadows: {
    default: '0px 4px 4px rgba(0, 0, 0, 0.06);',
  },
}
