import React from 'react'
import Logo from '@components/Logo'
import Chevron from '@components/Chevron'
import { useStaticQuery, graphql } from 'gatsby'
import { FooterQuery } from 'typings/contentful-queries'
import {
  BackToTop,
  FooterContainer,
  FooterWrapper,
  Disclaimer,
  FooterLinks,
  Copyright,
} from './Styles'

const Footer = () => {
  const data: FooterQuery = useStaticQuery(graphql`
    query Footer {
      contentfulFooter {
        backToTop
        footerText
        footerButtons {
          destination
          displayText
          contentfulid
        }
        copyrightText
      }
    }
  `)

  const { backToTop, footerText, footerButtons, copyrightText } = data.contentfulFooter || {}

  return (
    <FooterContainer>
      <BackToTop title={backToTop ?? 'Back to top'} id="back-to-top-button" href="#">
        <span>{backToTop}</span>
        <Chevron direction="up" />
      </BackToTop>
      <FooterWrapper>
        <Logo />
        <Disclaimer>{footerText}</Disclaimer>
        <FooterLinks>
          {footerButtons?.map(footerButton => (
            <a
              key={footerButton?.contentfulid ?? ''}
              title={footerButton?.displayText ?? ''}
              id={footerButton?.contentfulid ?? ''}
              href={footerButton?.destination ?? ''}
              target="_blank"
              rel="noopener noreferrer"
            >
              {footerButton?.displayText}
            </a>
          ))}
        </FooterLinks>
        <Copyright>
          © {new Date().getFullYear()}. {copyrightText}
        </Copyright>
      </FooterWrapper>
    </FooterContainer>
  )
}

export default Footer
