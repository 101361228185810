import React from 'react'
import theme from '@theme/theme'
import { Svg, ChevronContainer } from './Styles'

interface ChevronProps {
  size?: number
  direction?: 'up' | 'down'
  id: string
}

const Chevron = ({ size = 20, direction = 'down' }: ChevronProps) => (
  <ChevronContainer className="chevron-icon">
    <Svg
      size={size}
      width={size}
      height={size}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        transform={direction === 'up' ? 'rotate(0)' : 'rotate(-180 10 10)'}
        fill={theme.colors.skyBlue}
        d="m10.02671,19.99794c5.5228,0 10,-4.4772 10,-10c0,-5.52285 -4.4772,-10 -10,-10c-5.52285,0 -10,4.47715 -10,10c0,5.5228 4.47715,10 10,10zm8.2857,-10c0,4.5761 -3.7096,8.2857 -8.2857,8.2857c-4.57607,0 -8.28572,-3.7096 -8.28572,-8.2857c0,-4.57607 3.70965,-8.28572 8.28572,-8.28572c4.5761,0 8.2857,3.70965 8.2857,8.28572zm-3.5061,1.1544l-4.2513,-3.94966c-0.2939,-0.27299 -0.769,-0.27299 -1.05659,0l-4.25133,3.94966c-0.29384,0.2701 -0.29384,0.7115 0,0.9845l0.70647,0.6564c0.29072,0.2729 0.76587,0.2729 1.05971,0l3.01344,-2.7997l3.0134,2.7997c0.2908,0.2729 0.7659,0.2729 1.0597,0l0.7065,-0.6564c0.2939,-0.2701 0.2939,-0.7115 0,-0.9845z"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </Svg>
  </ChevronContainer>
)

export default Chevron
