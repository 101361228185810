import * as React from 'react'
import Helmet from 'react-helmet'
import { Global, ThemeProvider } from '@emotion/react'
import globals from '@theme/globals'
import theme from '@theme/theme'
import Header from '@components/Header'
import Footer from '@components/Footer'

import { BodyWrapper } from './Styles'

export interface LayoutProps {
  lang: string
}

const Layout: React.FC<LayoutProps> = ({ children, lang }) => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Global styles={globals} />
        <Helmet>
          <html lang={lang} />
          <meta charSet="utf-8" />
          <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        </Helmet>
        <Header />
        <BodyWrapper>{children}</BodyWrapper>
        <Footer />
      </ThemeProvider>
    </>
  )
}

export default Layout
