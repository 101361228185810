import styled from '@emotion/styled'
import AnchorLink from '@components/AnchorLink'
import { over } from '@theme/media'
import r from '@utils/pxToRem'

const BackToTop = styled(AnchorLink)`
  display: inline-flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.textGrey};
  text-decoration: none;
  font-size: ${({ theme }) => theme.fontSizes.backToTop[1]};
  font-weight: ${({ theme }) => theme.fontWeights.navigation};
  padding: ${r`11px 0`};

  > span {
    padding-right: ${r`8px`};
  }

  ${({ theme }) =>
    over.laptop`
      font-size: ${theme.fontSizes.backToTop[0]};
      padding: ${r`6px 0`};
  `};
`

const Disclaimer = styled.p`
  margin: ${r`16px 0px`};
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.footerText[1]};
  line-height: ${({ theme }) => theme.lineHeights.footerText[1]};
  ${({ theme }) =>
    over.laptop`
      margin: ${r`24px 0px 16px`};
      font-size: ${theme.fontSizes.footerText[0]};
      line-height: ${theme.lineHeights.footerText[0]};
  `};
`
const FooterLinks = styled.div`
  margin-bottom: ${r`16px`};
  display: flex;
  align-items: center;
  flex-direction: row;
  font-family: ${({ theme }) => theme.fonts.verdana};
  line-height: ${({ theme }) => theme.lineHeights.footerLink[1]};

  ${over.laptop`
    flex-direction: row;
  `}

  a {
    text-decoration: none;
    font-size: ${({ theme }) => theme.fontSizes.footerLink[1]};
    font-weight: ${({ theme }) => theme.fontWeights.footerLink};
    color: ${({ theme }) => theme.colors.white};
    padding: ${r`4px 8px`};
    &:hover {
      text-decoration: underline;
    }

    ${({ theme }) =>
      over.laptop`
      padding: ${r`8px 20px`};
      font-size: ${theme.fontSizes.footerLink[0]};
  `};
  }
`
const Copyright = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.footerText[1]};
  ${({ theme }) =>
    over.laptop`
      font-size: ${theme.fontSizes.footerText[0]};
  `};
`

const FooterWrapper = styled.div`
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.darkBlue};
  margin-top: ${r`76px`};
  padding: ${r`20px`};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
`

const FooterContainer = styled.footer`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;
`

export { BackToTop, FooterContainer, FooterWrapper, Disclaimer, FooterLinks, Copyright }
