import styled from '@emotion/styled'
import { under, over } from '@theme/media'

const HeaderWrapper = styled.header`
  display: flex;
  align-items: center;
  flex-direction: row;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.darkBlue};
  box-shadow: 0 0 5px 1px #c1c1c1;
  position: fixed;
  width: 100%;
  z-index: 15;
  height: ${({ theme }) => theme.elementSizes.header[0]}px;

  ${({ theme }) =>
    under.laptop`
      height: ${theme.elementSizes.header[1]}px;
  `};
`

const HeaderContainer = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex: 1;
  padding: 0 20px;
  box-sizing: border-box;
  max-width: ${({ theme }) => theme.elementSizes.maxPageWidth}px;
  margin: 0 auto;
  ${over.laptop`
    [data-reach-menu-popover] {
      display: block;
    }
  `}
  ${under.laptop`
    padding: 0 16px;
  `}

  ${({ theme }) =>
    under.laptop`
      [data-reach-menu-popover] {
        position: absolute;
        top: ${theme.elementSizes.header[1]}px;
        right: 0;
        width: 100%;
        z-index: 14;
      }
    `};
`

const HeaderLine = styled.div`
  ${({ theme }) =>
    over.laptop`
      position: absolute;
      top: 0;
      content: ' ';
      display: block;
      margin: auto;
      height: 4px;
      background: ${theme.colors.skyBlue};
      width: 100%;
  `};
`

export { HeaderWrapper, HeaderContainer, HeaderLine }
