import styled from '@emotion/styled'
import { MenuList, MenuItem } from '@reach/menu-button'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { under, over } from '@theme/media'
import r from '@utils/pxToRem'

const NavList = styled(MenuList)`
  display: flex;
  flex-direction: row;
  padding: ${r`16px 0 16px 16px`};
  line-height: ${({ theme }) => theme.lineHeights.navigation};
  background-color: ${({ theme }) => theme.colors.darkBlue};
  &:focus-visible {
    outline: none;
  }

  ${under.laptop`
    flex-direction: column;
    &[data-reach-menu-items] {
      animation: slide-down 0.2s ease;
    }
  `}

  ${under.phablet`
    padding: 24px 16px;
  `}
`
const NavListItem = styled(MenuItem)``

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const NavListLink = styled(AnchorLink as any)`
  position: relative;
  text-align: center;
  top: 4px;
  padding: ${r`24px 16px 20px`};
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes.navigation[1]};
  font-weight: ${({ theme }) => theme.fontWeights.navigation};
  line-height: 1.5;
  text-decoration: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.white};

  &:last-of-type {
    border-bottom: none;
  }

  ${({ theme }) =>
    under.phablet`
    font-size: ${theme.fontSizes.navigation[2]};
    padding: ${r`24px 0 20px`};
    span {
      display: inline-block;
      max-width: 22ch;
      word-wrap: break-word;
    }
  `};

  ${({ theme }) =>
    over.laptop`
    font-size: ${theme.fontSizes.navigation[0]};
  `};

  ${over.laptop`
    text-align: left;
    border-bottom: none;
    padding: ${r`0 20px`};
    
    &:hover::after {
      width: 100%;
    }

    &:last-of-type {
      margin-right: 0;
      padding-right: 0;
    }
  `}

  &.active {
    margin-bottom: -4px;
    border-bottom: 4px solid ${props => props.theme.colors.skyBlue};
    line-height: 1;
    &::after {
      content: ' ';
      display: block;
      margin: auto;
      width: 0%;
      transform: translateY(8px);
      border-bottom: 4px solid rgba(0, 0, 0, 0);
      transition: width 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }
  &::after {
    content: ' ';
    display: block;
    margin: auto;
    width: 0%;
    transform: translateY(8px);
    border-bottom: 4px solid ${props => props.theme.colors.skyBlue};
    transition: width 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  /* &:focus::after {
    width: 100%;
  } */
`

export { NavList, NavListItem, NavListLink }
