import styled from '@emotion/styled'
import { under } from '@theme/media'

const Svg = styled.svg`
  fill: ${({ theme }) => theme.colors.white};
  width: 50px;
  ${under.laptop`
    width: 32px;
    height: 32px;
  `}
`

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`

export { Svg, LogoContainer }
