import React from 'react'
import { Link } from 'gatsby'
import Logo from '@components/Logo'
import Navigation from '@components/Navigation'
import { HeaderWrapper, HeaderContainer, HeaderLine } from './Styles'

const Header = () => {
  return (
    <HeaderWrapper id="page-header" tabIndex={-1}>
      <HeaderLine />
      <HeaderContainer>
        <Link to="/" aria-label="Home">
          <Logo />
        </Link>
        <Navigation />
      </HeaderContainer>
    </HeaderWrapper>
  )
}

export default Header
